import * as React from "react";
import { graphql } from "gatsby";

import { removeDuplicates } from "@utils";

import Breadcrumbs from "@components/Breadcrumbs";
import Seo from "@components/Seo";

import SortingOpinions from "@page_components/opinions/SortingOpinions";

function OpinionsPageEN({ data }) {
  const { seo } = data.seo_data.nodes[0];
  const breadcrumbs_data = [{ name: "Opinions", href: `en/opinions` }];
  const opinions = data.allWpOpinion.nodes;
  const filters = removeDuplicates(
    opinions.map((item) => item.opinion.opinionOrigin)
  );

  return (
    <>
      <Seo title={seo.title} description={seo.metaDesc} />
      <div className="opinions-page">
        <div className="container">
          <Breadcrumbs data={breadcrumbs_data} />

          <main>
            <h1> Opinions </h1>
            <SortingOpinions filters={filters} opinions={opinions} />
          </main>
        </div>
      </div>
    </>
  );
}

export default OpinionsPageEN;

export const query = graphql`
  {
    allWpOpinion {
      nodes {
        uri
        opinion {
          opinionOrigin
          opinionDescription
          opinionAuthor
        }
      }
    }
    seo_data: allWpPage(filter: { title: { eq: "Opinions" } }) {
      nodes {
        seo {
          title
          metaDesc
        }
      }
    }
  }
`;
