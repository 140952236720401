import "./styles.scss";

import React, { useState } from "react";
import { useLocale } from "@context/locale";

import Opinion from "@components/Opinion";
import FiltrLabel from "@components/FiltrLabel";
import Button from "@components/Button";

function SortingOpinions({ filters, opinions }) {
  const { t } = useLocale();

  const [current_category, setCurrentCategory] = useState("Wszystkie");
  const [limit_opinions, setLimitOpinions] = useState(6);
  const [sortedOpinions, setSortedOpinions] = useState(opinions);

  const handleCheckedFiltr = (filtr) => {
    setCurrentCategory(filtr);
    if (filtr === "Wszystkie") {
      setSortedOpinions(opinions);
    } else {
      setSortedOpinions(
        opinions.filter((item) => item.opinion.opinionOrigin === filtr)
      );
    }
  };

  return (
    <div className="sorting-opinions">
      <div className="sorting-opinions__category">
        <p>{t("Zobacz opinie z danego źródła")}</p>
        <div className="sorting-opinions__label-list">
          <FiltrLabel
            name="Wszystkie"
            handleChecked={() => handleCheckedFiltr("Wszystkie")}
            checked={current_category === "Wszystkie"}
          />
          {filters.map((category_name) => (
            <FiltrLabel
              name={category_name}
              handleChecked={() => handleCheckedFiltr(category_name)}
              key={category_name}
              checked={current_category === category_name}
            />
          ))}
        </div>
      </div>

      <div className="sorting-opinions__list">
        {sortedOpinions.map((item, index) => {
          if (index < limit_opinions)
            return (
              <Opinion
                user={item.opinion.opinionAuthor}
                origin={item.opinion.opinionOrigin}
                contents={item.opinion.opinionDescription}
                variant="card"
                color="black"
                rate={item.opinion.opinionRate}
                uri={item.uri}
                key={item.opinion.opinionAuthor}
              />
            );
          return null;
        })}
      </div>
      {sortedOpinions.length > limit_opinions && (
        <Button
          onClick={() => setLimitOpinions(limit_opinions + 6)}
          className="sorting-opinions__button"
        >
          {t("Pokaż więcej opinii")}
        </Button>
      )}
    </div>
  );
}

export default SortingOpinions;
